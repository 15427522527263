import 'core-js/stable';
import 'regenerator-runtime/runtime';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'jquery-validation'
import 'jquery-validation-unobtrusive'

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
